@font-face {
    font-family: "SF Mono";
    font-weight: normal;
    src: url("../src/fonts/sf-mono/SFMonoRegular.ttf") format("truetype");
}
@font-face {
    font-family: "SF Mono";
    font-weight: bold;
    src: url("../src/fonts/sf-mono/SFMonoBold.ttf") format("truetype");
}
@font-face {
    font-family: "SF Mono";
    font-weight: lighter;
    src: url("../src/fonts/sf-mono/SFMonoLight.ttf") format("truetype");
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
}
html, body {
    background: black;
}

html, body, .Toastify__toast {
font-family: "SF Mono", "Roboto", Arial, Helvetica, sans-serif;
}

.Toastify__toast--info {
    background-color: #A0AEC0 !important;
}
.Toastify__toast--success {
    background-color: #48BB78 !important;
}
.Toastify__toast--error {
    background-color: #F56565 !important;
}